import React from 'react';
import merge from 'merge';
import { mutateState, injectId, guid } from 'libs/utility';
import cache from 'libs/cache';
import { app } from 'apps';
export const Store = React.createContext();

let state = {};
let locked = false;

if (app && app.type === 'project') {
  // try app config
  state = {
    ...app,
    _locked: true,
  };
  locked = true;
} else {
  // try from cache
  let projectId = cache.get('last-project') || 'project-default';
  state = cache.get(projectId) || {};
}

const initialState = {
  id: guid(),
  type: 'project',
  ...state,
  routes: generateRoutes(state),
};

let undoStack = {
  [`project-${initialState.id}`]: {
    init: JSON.stringify(initialState),
    stack: [],
    undoLength: 0,
  },
};

function pushToUndo(state, params) {
  let projectName = `project-${state.id}`;

  if (!undoStack[projectName]) {
    // not ready
    return Promise.resolve();
  }

  let stack = undoStack[projectName].stack;

  new Promise((resolve, reject) => {
    //-------------
    // undo
    //-------------
    if (params.type === 'project') {
      // console.log('init the stack');
      undoStack[projectName] = {
        init: JSON.stringify(state),
        stack: [],
      };
    } else {
      let codedAction = JSON.stringify(params);
      let lastItem = stack[stack.length - 1];
      if (lastItem !== codedAction) {
        stack.push(codedAction);
      }
    }

    resolve();
  });
}

// console.log(initialState);
window.$u = undoStack;

export function generateRoutes(state) {
  let routes = [];
  if (state) {
    let pages = (state.children || []).filter((p) => {
      return p.type === 'page';
    });
    routes = pages.map((p) => {
      return {
        page: p.name,
        path: p.route,
        layout: p.layout,
        requireAuth: p.requireAuth || false,
        node: {
          _id: p.id,
          type: p.type,
        },
        component: (props) => <pre>{JSON.stringify(props, null, 4)}</pre>,
      };
    });
  }
  return routes;
}

/* params: { path:value } */
export function setState(params) {
  return {
    _type: 'SET_STATE',
    ...params,
  };
}

export function regenerateRoutes(params) {
  return {
    _type: 'GEN_ROUTES',
    ...params,
  };
}

export function undo(params) {
  return {
    _type: 'UNDO',
    ...params,
  };
}

export function reducer(state, action) {
  let params = { ...action };
  let projectName = `project-${state.id}`;

  undoStack[projectName] = undoStack[projectName] || {
    stack: [],
    undoLength: 0,
  };
  let stack = undoStack[projectName].stack;

  switch (action._type) {
    case 'SET_STATE':
      if (locked) {
        return state;
      }
      delete params._type;

      state = mutateState(state, params);
      pushToUndo(state, params);

      return {
        ...state,
        undoLength: undoStack[projectName].stack.length,
      };

    case 'GEN_ROUTES':
      return {
        ...state,
        routes: generateRoutes(params),
      };

    case 'UNDO':
      {
        state = JSON.parse(undoStack[projectName].init);
        if (stack.length > 0) {
          stack.pop();
        }
        stack.forEach((act) => {
          state = mutateState(state, JSON.parse(act));
        });

        state.undoLength = stack.length;
        state.updateKey = guid();
      }

      return state;
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const config = merge.recursive(initialState, props.config || {});
  const [state, dispatch] = React.useReducer(reducer, config);
  const value = { state, dispatch, setState, regenerateRoutes, undo };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

export function useApp() {
  return React.useContext(Store);
}
