import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import { useUI } from 'stores/UIStore';
import StateHelper from 'libs/stateHelper';
import { Typography, Select, MenuItem } from '@material-ui/core';
import clsx from 'clsx';

const cx = new StateHelper();

const VisitorPass = (props) => {
  const ui = useUI();
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const name = node.name || '';

  const getView = (n) => {
    const viewIndex = children.findIndex((x) => x.name === n);
    return (
      <React.Fragment>
        {viewIndex >= 0 ? (
          RenderRegistry.renderChildren([children[viewIndex]], {
            ...props,
            context: context,
          })
        ) : (
          <pre>not found</pre>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div key={context._state.data.type}>
        {context._state.data.type &&
          getView(`${name}-${context._state.data.type}`)}
      </div>
    </React.Fragment>
  );
};

RenderRegistry.add({
  VisitorPass,
});
