import React from 'react';
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';

import RenderRegistry from '../RenderRegistry';

import { isRequired } from 'libs/validators';

import { format as $format } from 'date-fns';

const keyboardButtonProps = { 'aria-label': 'change date' };

// const DatePickerMemo = React.memo(KeyboardDateTimePicker, (prev, next) => {
//   return (
//     prev.error === next.error &&
//     prev.value === next.value &&
//     prev.label === next.label &&
//     prev.description === next.description
//   );
// });

const StatefulKeyboardDateTimePicker = (props) => {
  let node = props.node;
  let fs = props.context;

  let [open, setOpen] = React.useState(false);

  let m = {};
  let mI = {};

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    if (fs.model) {
      m = { ...fs.model(modelName) };
      mI = (fs.modelInfo ? fs.modelInfo[dataField] : {}) || {};
    }
  }

  const handleChange = (value) => {
    let errors = fs.validate(modelName, value);
    fs.setState({
      [modelName]: value,
      _errors: errors,
    });
  };

  // TODO!
  let desc = node.description;
  if (m.error) {
    desc = m.message;
  }

  let format = node.format || 'MM/dd/yyyy hh:mm a';
  let disabled = fs._state['no-access'] || node.disabled || false;

  const handleClick = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  let required = node.required || false;

  React.useEffect(() => {
    if (required) {
      fs.useValidator({ [modelName]: isRequired });
    }
    return () => {
      if (required) {
        fs.useValidator({ [modelName]: false });
      }
    };
  }, []);

  // let defaultValue = $format(new Date(), 'MM/dd/yyyy hh:mm a');

  // React.useEffect(() => {
  //   if (!fs) {
  //     return;
  //   }
  //   console.log(modelName);
  //   handleChange(defaultValue);
  // }, []);

  return (
    <div className="form-label" style={{ flex: 1, margin: '4px' }}>
      {/*<pre>{JSON.stringify(m, null, 4)}</pre>*/}
      <Typography variant="h6">
        {required ? `${node.label} *` : node.label}
      </Typography>
      <KeyboardDateTimePicker
        format={format}
        open={open}
        margin="dense"
        {...m}
        onChange={handleChange}
        inputVariant="outlined"
        //defaultValue={defaultValue}
        fullWidth
        onClick={handleClick}
        disabled={disabled}
        onClose={handleClick}
        helperText={desc}
        hideTabs
      />
    </div>
  );
};

RenderRegistry.add({
  inputDateTime: StatefulKeyboardDateTimePicker,
});
