import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import events from 'libs/events';
import TablePagination from '@material-ui/core/TablePagination';
import qs from 'qs';

import { Table, Header, Body, Column } from 'components/table/Table';

import { isValid, format as $format } from 'date-fns';

const Element = withRouter((props) => {
  let { node, history } = props;
  let columns = [];
  let columnsRendered = [];

  let q = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  let p = 0;

  if (q.page) {
    p = q.page;
  }

  const [page, setPage] = React.useState(p);

  const RenderColumn = RenderRegistry.view;

  node.children.forEach((c, idx) => {
    if (c.type !== 'column') {
      return;
    }

    let fieldName = c.dataField || c.name || '_data';
    columns.push({
      name: fieldName,
      label: c.label || c.name,
      date: c.date,
      format: c.format,
      currency: c.currency,
      width: c.width,
    });

    if (c.children && c.children.length) {
      columnsRendered.push(
        <Column key={`column-${idx}`} field={fieldName}>
          {(props) => {
            let n = {
              ...c,
              dataField: `${props.row}.${fieldName}`,
              row: props.row,
            };
            return (
              <React.Fragment>
                <RenderColumn
                  {...props}
                  context={props.context}
                  node={n}
                  dataField={n.dataField}
                ></RenderColumn>
                {/*{fieldName}<pre>{JSON.stringify(props,null,4)}</pre>*/}
              </React.Fragment>
            );
          }}
        </Column>
      );
    }
  });

  let data = props.context.getState('data');
  let count = props.context.getState('count');
  if (!data || !data.length) {
    data = [];
  }

  const onRowClick = (evt, row) => {
    if (node.path) {
      if (node.path !== '#') {
        props.history.push(node.path + '/' + row.data._id);
      }
    } else if (node.command) {
      events.$emit(node.command, row.data);
    } else {
      events.$emit('open', row.data);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > 0) {
      history.replace(`${props.location.pathname}?page=${newPage}`);
    } else {
      history.replace(`${props.location.pathname}`);
    }
    setPage(newPage);
  };

  return (
    <React.Fragment>
      {/*JSON.stringify(props.context.state())*/}
      <Table columns={columns} data={data} context={props.context}>
        <Header></Header>
        <Body onClick={onRowClick} hover={true}>
          {/*columnsRendered*/}
        </Body>
      </Table>
      {[count || 0] > 50 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={50}
          page={page}
          onChangePage={handleChangePage}
        />
      )}
    </React.Fragment>
  );
});

RenderRegistry.add({
  table: Element,
});
