import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { isRequired } from 'libs/validators';

// const TextFieldMemo = React.memo(TextField, (prev, next) => {
// return (
// prev.value === next.value &&
// prev.label === next.label &&
// prev.description === next.description
// );
// });

const StatefulList = (props) => {
  let node = props.node;
  let fs = props.context;
  const app = useApp();

  let m = {};
  let mI = {};

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    m = { ...fs.model(modelName) };
    mI = (fs.modelInfo ? fs.modelInfo[dataField] : {}) || {};
  }

  let desc = node.description;
  if (m.error) {
    desc = 'Please fill up at least one';
  }

  const addItem = () => {
    const value = [...m.value];
    value.push('');
    let errors = fs.validate(modelName, value);
    fs.setState({ [modelName]: value, _errors: errors });
  };

  const onListItemChange = (val, idx) => {
    const value = [...m.value];
    value[idx] = val;
    fs.setState({ [modelName]: value });
  };

  const deleteItem = (idx) => {
    const value = m.value.filter((el, i) => {
      return idx !== i;
    });
    fs.setState({ [modelName]: value });
  };

  React.useEffect(() => {
    const value = [...m.value];
    if (value.length <= 0) {
      value.push('');
      fs.setState({ [modelName]: value });
    }
  }, []);

  const placeholder = node.placeholder || 'Item';

  let required = node.required || false;
  let disabled = fs._state['no-access'] || node.disabled || false;

  React.useEffect(() => {
    if (required) {
      fs.useValidator({ [modelName]: isRequired });
    }
    return () => {
      if (required) {
        fs.useValidator({ [modelName]: false });
      }
    };
  }, []);

  const listItems =
    typeof m.value === 'object'
      ? m.value.map((el, idx) => {
          const x = fs.model(`${modelName}.${idx}`);
          return (
            <ListItem disableGutters key={idx}>
              <TextField
                fullWidth
                margin="dense"
                type="text"
                variant="outlined"
                placeholder={`${placeholder} ${idx + 1}`}
                value={m.value[idx]}
                disabled={disabled}
                {...x}
              />
              <ListItemSecondaryAction>
                <IconButton
                  disabled={disabled}
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    deleteItem(idx);
                  }}
                >
                  <DeleteIcon color="danger" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      : null;

  return (
    <div
      style={{ flex: 1, margin: '4px' }}
      className={clsx('form-label', node.className)}
    >
      {/*<pre>{JSON.stringify(m, null, 4)}</pre>*/}
      <Typography variant="h6">
        {required ? `${node.label} *` : node.label}
      </Typography>
      <List dense>
        {listItems}
        {!disabled && (
          <ListItem
            disabled={disabled}
            disableGutters
            button
            key="new"
            onClick={addItem}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Add ${placeholder}`} />
          </ListItem>
        )}
      </List>
      <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
        {desc}
      </p>
    </div>
  );
};

RenderRegistry.add({
  inputList: StatefulList,
});
