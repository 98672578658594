import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import { useUI } from 'stores/UIStore';
import StateHelper from 'libs/stateHelper';
import { Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import events from 'libs/events';
import { useModal } from 'components/Modal';
import http from 'libs/http';
import config from 'config/config';
import { useSnackbar } from 'notistack';
import crud from 'libs/crud';

const cx = new StateHelper();

const BillingModal = (props) => {
  const [state, setState] = React.useState({
    file: { name: 'SELECT .xlsx' },
    isLoading: false,
  });
  const buttonUpload = React.useRef(null);
  const { closeModal } = useModal();
  const { snackbar } = props;

  const onFileChange = (evt) => {
    setState({ ...state, file: evt.target.files[0] });
  };

  const onUpload = async () => {
    setState({ ...state, isLoading: true });
    let fd = new FormData();
    fd.append('file', state.file);
    await http
      .post(config.app.server.url + `/billings/upload`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        events.$emit('fetchData', {});
        snackbar('File uploaded successfully', { variant: 'success' });
      })
      .catch((err) => {
        snackbar('Error uploading file', { variant: 'error' });
      })
      .finally(() => {
        closeModal();
      });
  };

  const onUploadClick = () => {
    buttonUpload.current.click();
  };

  return (
    <div>
      {!state.isLoading ? (
        <div>
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style={{ display: 'none' }}
            type="file"
            ref={buttonUpload}
            onChange={onFileChange}
          />
          <div className="billing-upload-field" onClick={onUploadClick}>
            {state.file.name}
          </div>
          <div class="row mt-3">
            <Button
              className="mx-1"
              onClick={onUpload}
              color="primary"
              variant="contained"
            >
              Upload
            </Button>
            <Button
              className="mx-1"
              onClick={closeModal}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="billing-upload-loading">
          <CircularProgress color="primary" />
        </div>
      )}
    </div>
  );
};

const BillingUpload = (props) => {
  const ui = useUI();
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const { enqueueSnackbar } = useSnackbar();

  const { showModal, closeModal } = useModal();

  const onNewBilling = () => {
    showModal({
      title: 'Upload New SOA',
      message: <BillingModal snackbar={enqueueSnackbar} />,
    });
  };

  const componentEvents = {
    new_billing: onNewBilling,
  };

  React.useEffect(() => {
    events.register(componentEvents);
    return () => {
      events.unregister(componentEvents);
    };
  }, []);

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: context,
      })}
    </React.Fragment>
  );
};

const ArchivedBillings = (props) => {
  const [state, setState] = React.useState({ data: [] });
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const n = crud('billings/archive');

  cx.useState(state, setState);

  const getNotifs = async () => {
    let res = await n.find({ _sort: 'createdAt:DESC' });
    if (res.data) {
      cx.setState({ data: res.data });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getNotifs();
      console.log('getting notifs');
    }, 800);
  }, []);

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </React.Fragment>
  );
};

const TotalBalance = (props) => {
  const { context } = props;
  let total = 0;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
  });

  if (context) {
    if (context._state) {
      if (context._state.data) {
        console.log(context._state.data);
        if (Array.isArray(context._state.data)) {
          let t = 0;
          for (const data of context._state.data) {
            console.log(data);
            t += parseFloat(data.amount);
          }
          total = formatter.format(t);
        }
      }
    }
  }

  return (
    <div className="my-1">
      Total: <span className="has-text-weight-semibold">{total}</span>
    </div>
  );
};

RenderRegistry.add({
  BillingUpload,
  ArchivedBillings,
  TotalBalance,
});
