import React, { Fragment } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import RenderRegistry from '../RenderRegistry';
import StateHelper from 'libs/stateHelper';
import { findById } from 'libs/utility';
import crud from 'libs/crud';
import pluralize from 'pluralize';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'stores/AppStore';
import { Typography } from '@material-ui/core';
import { isRequired } from 'libs/validators';

const SelectMemo = React.memo(Select, (prev, next) => {
  return (
    prev.value === next.value &&
    prev.optionscount === next.optionscount &&
    prev.label === next.label &&
    prev.description === next.description
  );
});

const StatefulSelect = (props) => {
  let app = useApp();
  let node = props.node;
  let fs = props.context;

  let m = {};
  let model_ = {};
  let mI = {};
  let rI = {};

  //let modelName = `data.${node.dataField || node.name}`;

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    if (fs.model) {
      m = { ...fs.model(modelName) };
      model_ = { ...fs.model(`${modelName}sadsadsa`) };
      mI = (fs.modelInfo ? fs.modelInfo[dataField] : {}) || {};
    }
  }

  console.log(model_);

  let errorFlag = false;

  let desc = node.description;
  if (m.error) {
    errorFlag = true;
    desc = m.message;
  }

  // /console.log(m);

  const label = node.label || node.name;
  const nullable = node.nullable || false;
  const preselected = node.preselected || false;
  const value =
    fs.getState(modelName) !== undefined ? fs.getState(modelName) : '';

  if (node.relation) {
    rI = findById(app.state, node.relation, { key: 'id' });
    //console.log(app.state);
  }

  let opts = [];
  try {
    opts = JSON.parse(node.options) || [];
  } catch (err) {}
  let [options, setOptions] = React.useState(opts);

  const loadOptions = async () => {
    try {
      if (rI && rI.name) {
        let model = crud(pluralize(rI.name));
        let res = await model.find({ _limit: 10000, _sort: 'createdAt:DESC' });
        let opts = [];

        if (nullable) {
          opts.push({
            value: 'all',
            label: 'Send To All',
            data: null,
          });
        }

        (res.data || []).forEach((d) =>
          opts.push({
            value: d._id || d.id,
            label:
              d.name ||
              d.description ||
              d.number ||
              d.firstName + ' ' + d.lastName,
            data: { ...d },
          })
        );

        setOptions(opts);
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    loadOptions();
  }, [rI]);

  React.useEffect(() => {
    if (!fs) {
      return;
    }

    let _v = value;

    if (value) {
      if (typeof value == 'object') {
        console.log('object');
        _v = value['_id'];

        fs.setStateWithWait({
          [`${modelName}`]: value['_id'],
        });
      }
    }

    let idx = options.findIndex((x) => {
      console.log(x);
      return x.value === _v;
    });

    if (idx > -1) {
      if (value == null) {
        idx = 0;
      }
    }

    if (options[idx] !== undefined) {
      if (node.saveObject || (rI && rI.name)) {
        fs.setState({
          [`${modelName}_`]: options[idx].data
            ? options[idx].data
            : options[idx],
        });
        setInputValue(options[idx].label);
      }
    }
  }, [value]);

  React.useEffect(() => {
    if (preselected) {
      if (options.length > 0) {
        fs.setStateWithWait({
          [`${modelName}`]: options[0].value,
        });
      }
    }
  }, [options]);

  let optCount = options.length || 0;

  let required = node.required || false;

  let placeholder = node.placeholder || 'Choose One...';

  const [inputValue, setInputValue] = React.useState('Choose one...');

  let disabled = fs._state['no-access'] || node.disabled || false;

  React.useEffect(() => {
    if (required) {
      fs.useValidator({ [modelName]: isRequired });
    }
    return () => {
      if (required) {
        fs.useValidator({ [modelName]: false });
      }
    };
  }, []);

  const handleChange = (evt) => {
    let errors = fs.validate(modelName, evt.target.value);
    m.onChange(evt);
  };

  const onAutoCompleteChange = (event, newValue) => {
    //let evt = {target: newInputValue.value};
    console.log(newValue);
    if (newValue) {
      if (newValue.value) {
        let errors = fs.validate(modelName, newValue.value);
        fs.setStateWithWait({
          [`${modelName}`]: newValue.value,
        });
      }
    }
  };

  const onAutoCompleteInputChange = (event, newInputValue) => {
    console.log(newInputValue);
    setInputValue(newInputValue);
  };

  return (
    <div className="form-label" style={{ flex: 1, margin: '4px' }}>
      {/*<pre>{JSON.stringify(fs, null, 4)}</pre>*/}
      {/*<pre>{JSON.stringify(m.value, null, 4)}</pre>*/}

      {rI && rI.name ? (
        <div>
          {typeof value == 'string' && (
            <Autocomplete
              key={`select-${modelName}-${optCount}-${value}`}
              disabled={disabled}
              value={value}
              inputValue={inputValue}
              autoSelect
              onChange={onAutoCompleteChange}
              onInputChange={onAutoCompleteInputChange}
              options={options}
              getOptionSelected={(option, val) => option.value === val}
              getOptionLabel={(option) => option.label}
              style={{ width: 300 }}
              renderInput={(params) => (
                <div>
                  <Typography variant="h6">
                    {required ? `${node.label} *` : node.label}
                  </Typography>
                  <TextField
                    disabled={disabled}
                    fullWidth
                    margin="dense"
                    {...params}
                    variant="outlined"
                  />{' '}
                </div>
              )}
            />
          )}
        </div>
      ) : (
        <FormControl fullWidth error={errorFlag}>
          <Typography variant="h6">
            {required ? `${label} *` : label}
          </Typography>
          <Select
            key={`select-${modelName}-${optCount}-${value}`}
            disabled={disabled}
            labelId={modelName}
            id={modelName}
            optionscount={optCount}
            margin="dense"
            variant="outlined"
            helperText={desc}
            {...m}
            onChange={handleChange}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
            {options.map((option) => {
              let _opt = { ...option };
              if (typeof option === 'string') {
                _opt = {
                  label: option,
                  value: option,
                };
              }
              return (
                <MenuItem key={_opt.value} value={_opt.value}>
                  {_opt.label}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{desc}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
};

export default StatefulSelect;

RenderRegistry.add({
  inputSelect: StatefulSelect,
});
