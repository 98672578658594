import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import StateHelper from 'libs/stateHelper';
import clsx from 'clsx';
import crud from 'libs/crud';

const cx = new StateHelper();

const Notifications = (props) => {
  const [state, setState] = React.useState({ data: [] });
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const n = crud('notifications/public');

  cx.useState(state, setState);

  const getPublicNotifs = async () => {
    let res = await n.find({ _sort: 'createdAt:DESC' });
    let notifs = context._state.data;
    if (res) {
      if (res.data) {
        let ns = [...notifs, ...res.data];
        //sort
        ns = ns.filter((x) => {
          if (x.noticeStart === undefined) {
            return true;
          }

          if (x.noticeEnd === undefined) {
            return true;
          }
          return (
            new Date(x.noticeStart) <= new Date() &&
            new Date(x.noticeEnd) >= new Date()
          );
        });
        console.log(ns);
        ns = ns.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        cx.setState({ data: ns });
      }
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getPublicNotifs();
    }, 800);
  }, []);

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </React.Fragment>
  );
};

const ArchivedNotifications = (props) => {
  const [state, setState] = React.useState({ data: [] });
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const n = crud('notifications/archive');

  cx.useState(state, setState);

  const getNotifs = async () => {
    let res = await n.find({ _sort: 'createdAt:DESC' });
    if (res.data) {
      cx.setState({ data: res.data });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getNotifs();
      console.log('getting notifs');
    }, 800);
  }, []);

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </React.Fragment>
  );
};

const AllNotifications = (props) => {
  const [state, setState] = React.useState({ data: [] });
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const n = crud('notifications/all');

  cx.useState(state, setState);

  const getNotifs = async () => {
    let res = await n.find({ _sort: 'createdAt:DESC' });
    if (res.data) {
      cx.setState({ data: res.data });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getNotifs();
      console.log('getting notifs');
    }, 800);
  }, []);

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: cx,
      })}
    </React.Fragment>
  );
};

RenderRegistry.add({
  Notifications,
  ArchivedNotifications,
  AllNotifications,
});
