import React from 'react';
import UploadField from 'components/UploadField/UploadField';
import FileList from 'components/UploadField/FileList';
import RenderRegistry from '../RenderRegistry';
import Typography from '@material-ui/core/Typography';
import { isRequired } from 'libs/validators';
import { useApp } from 'stores/AppStore';

const StatefulUpload = (props) => {
  // let fs = props.context;
  // let files = fs.getState(props.model) || [];

  const app = useApp();

  let node = props.node;
  let basepath = node.basePath || '/';
  let fs = props.context;

  let m = {};
  let mI = {};

  let dataField = props.dataField || node.dataField || node.name;
  let modelName = `data.${dataField}`;

  if (fs) {
    m = { ...fs.model(modelName) };
    mI = (fs.modelInfo ? fs.modelInfo[node.dataField] : {}) || {};
  }

  let desc = node.description;
  if (m.error) {
    desc = 'Please select at least one photo.';
  }

  let label = node.label;

  let required = node.required || false;

  React.useEffect(() => {
    if (required) {
      fs.useValidator({ [modelName]: isRequired });
    }
    return () => {
      if (required) {
        fs.useValidator({ [modelName]: false });
      }
    };
  }, []);

  let disabled = fs._state['no-access'] || node.disabled || false;

  return (
    <div className="form-label" style={{ flex: 1, margin: '4px' }}>
      <Typography variant="h6">{required ? `${label} *` : label}</Typography>
      <div
        className={
          m.error
            ? 'upload-required-error no-padding-container'
            : 'no-padding-container'
        }
        style={{ padding: '0.5em' }}
      >
        {!disabled && <UploadField basepath={basepath} {...m} />}
        <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
          {desc}
        </p>
      </div>
      <FileList
        {...m}
        context={fs}
        download={app.state.activeAppName == 'dashboard'}
        delete={!disabled}
      />
    </div>
  );
};

RenderRegistry.add({
  inputMedia: StatefulUpload,
});
