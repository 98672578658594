import React from 'react';
import { useApp } from 'stores/AppStore';
import { useDomains } from 'stores/DomainStore';

import StateHelper from 'libs/stateHelper';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import events from 'libs/events';

import $http from 'libs/http';
import config from 'config/config';
import { useAuth } from 'stores/AuthStore';
import { useSnackbar } from 'notistack';
import { isRequired } from 'libs/validators';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { useModal } from 'components/Modal';
import cache from 'libs/cache.js';

const cx = new StateHelper();

const Login = withRouter((props) => {
  let node = props.node;

  const domains = useDomains();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal, closeModal } = useModal();

  const app = useApp();

  const [state, setState] = React.useState({
    data: {
      email: '',
      password: '',
      persist: true,
    },
  });

  cx.useState(state, setState);
  cx.useValidator({
    data: {
      email: {
        isRequired,
      },
      password: {
        isRequired,
      },
    },
  });

  const _portalLogin = async () => {
    let res = {};

    try {
      res = await $http({
        method: 'post',
        url: config.auth.server.url + `/auth-portal/login`,
        data: {
          ...state.data,
        },
      });

      if (res.data.portals.length > 0) {
        domains.dispatch(
          domains.setState({
            portals: res.data.portals,
          })
        );
        props.history.replace('/login-portal');
      } else {
        enqueueSnackbar('Login failed! Please try again.', {
          variant: 'error',
        });
      }
    } catch (err) {
      res.data = {};
    }
  };

  const _portalToLocalLogin = async (p) => {
    // todo ..change config here!

    console.log(config);
    console.log(p);

    const user = {
      user: p.data.user,
      jwt: p.data.jwt,
      provider: {
        provider: 'local',
      },
    };

    auth.dispatch(auth.setUser(user));
    props.history.replace('/');
  };

  const _localLogin = async () => {
    let res = {};

    try {
      res = await $http({
        method: 'post',
        url: config.app.server.url + `/auth-local/login`,
        data: {
          ...state.data,
        },
      });
    } catch (err) {
      res.data = {};
    }

    if (!res.data.user) {
      // console.log('invalid login');
      enqueueSnackbar('Login failed! Please try again.', { variant: 'error' });
      return;
    }

    if (app.state.activeAppName === 'dashboard') {
      if (res.data.user['role_'] === 'homeowner') {
        enqueueSnackbar('Login failed! Please try again.', {
          variant: 'error',
        });
        return;
      }
    }

    const user = {
      user: res.data.user,
      jwt: res.data.jwt,
      provider: {
        provider: 'local',
      },
      lastLogin: new Date(),
    };

    auth.dispatch(auth.setUser(user));
    props.history.replace('/');
  };

  const onLocalLogin = async () => {
    await cx.validateStateWithWait();
    if (cx.hasErrors()) {
      enqueueSnackbar('Please fill the login form.', { variant: 'error' });
      return;
    }
  };

  const onPortalLogin = async (e) => {
    e.preventDefault();
    await cx.validateStateWithWait();
    if (cx.hasErrors()) {
      enqueueSnackbar('Please fill the login form.', { variant: 'error' });
      return;
    }

    if (app.state.activeAppName === 'dashboard') {
      _localLogin();
      return;
    }

    _portalLogin();
  };

  const componentEvents = {
    local_login: onLocalLogin,
    portal_login: onPortalLogin,
  };

  React.useEffect(() => {
    auth.dispatch(auth.setUser({}));
    domains.dispatch(
      domains.setState({
        portals: [],
      })
    );
  }, []);

  React.useEffect(() => {
    events.register(componentEvents);
    return () => {
      events.unregister(componentEvents);
    };
  });

  // todo -- separated from login screen
  let portalsRendered = domains.state.portals.map((p, idx) => {
    const img = p.portal.logo || './assets/images/icon_property.svg';
    const col = p.portal.color || '#000';
    return (
      <div className="app-menu-item-container" key={`portal-${idx}`}>
        <div className="app-menu-item-login">
          <Button
            onMouseEnter={(e) => {
              e.currentTarget.style.border = `5px solid ${col}`;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.border = 'none';
            }}
            className="btn-app-menu-item-login"
            key={`portal-${idx}`}
            onClick={(e) => {
              e.currentTarget.style.border = `5px solid ${col}`;
              _portalToLocalLogin(p);
            }}
          >
            <div className="inner-button">
              <div class="inner-content-button">
                {p.portal.logo ? (
                  <img className="m-2" src={img} />
                ) : (
                  <object type="image/svg+xml" data={img}>
                    <img src={img} />
                  </object>
                )}
              </div>
            </div>
          </Button>
        </div>
      </div>
    );
  });

  return (
    <div className="login-container">
      <div className="login-form-container">
        <form onSubmit={onPortalLogin}>
          {RenderRegistry.renderChildren(node.children, {
            ...props,
            context: cx,
          })}
        </form>
      </div>
    </div>
  );
});

RenderRegistry.add({
  Login,
});
