import React from 'react';
import merge from 'merge';
import clsx from 'clsx';
import { findById } from 'libs/utility';
import { useAuth } from 'stores/AuthStore';

function Render(props) {
  let node = props.node;
  let Component = node.component
    ? RenderRegistry[node.component]
    : RenderRegistry[node.type];

  let auth = useAuth();

  if (node.access) {
    if (Object.keys(auth.state.user).length > 0) {
      let role = auth.state.user['role_'] || 'admin';

      let roles = node.access.split(',');

      if (!roles.includes(role)) {
        if (node.type !== 'page') {
          return <div></div>;
        }
      }
    }
  }

  if (!Component) {
    Component = (props) => (
      <div>
        Unhandled: {node.component || node.type} {node.nodeType}
      </div>
    );
  }

  return <Component {...props} />;
}

function renderChildren(children, props) {
  return (children || []).map((node, idx) => {
    let customClass = 'bite-' + (node.name || '').replace(/\s/g, '-');
    let className = node.className || '';
    return (
      <Render
        {...props}
        className={clsx(customClass, className)}
        node={node}
        key={`cp-${idx}`}
      />
    );
  });
}

function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const RenderRegistry = {
  Render,
  renderChildren,

  add: (config) => {
    Object.keys(config).forEach((k) => {
      let componentInfo = config[k];
      RenderRegistry[k] = componentInfo;
    });
  },

  get: (type) => {
    return RenderRegistry[type];
  },
};

export default RenderRegistry;
