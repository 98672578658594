import React from 'react';

import { Link as RouterLink, withRouter, useLocation } from 'react-router-dom';

import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { useUI } from 'stores/UIStore';
import { useApp } from 'stores/AppStore';
import clsx from 'clsx';

import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Icon from 'components/icons/Icon';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  flexGrowMore: {
    flexGrow: 10,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Topbar = withRouter((props) => {
  const ui = useUI();
  const app = useApp();

  const isBrowser = window.cordova === undefined;
  const location = useLocation();

  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();

  // find topbar
  let topbar =
    (app.state.children || []).filter((s) => {
      return s.name === 'topbar';
    })[0] || {};

  const actions = ui.state.topbar.items.map((item, idx) => {
    let icon = item.icon ? <Icon icon={item.icon} /> : '';
    return (
      <Button color="inherit" key={idx} onClick={item.action}>
        {icon}
        {item.label && <span>{item.label}</span>}
      </Button>
    );
  });

  const onBack = () => {
    props.history.goBack();
  };

  const show = () => {
    if (
      !isBrowser &&
      app.state.activeAppName == 'homeowners' &&
      location.pathname == '/app'
    ) {
      return true;
    }

    if (isBrowser && app.state.activeAppName == 'homeowners') {
      return true;
    }

    return false;
  };

  return (
    <AppBar color={rest.color} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="primary" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        {!isBrowser && location.pathname !== '/app' && (
          <IconButton color="primary" onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {show() && (
          <Button
            onClick={() => {
              props.history.push('/login-portal');
            }}
            color="primary"
          >
            Select Property
          </Button>
        )}
        <div className={classes.flexGrow} />
        <RouterLink className="app-logo" to="/">
          <img
            alt="Logo"
            src="./assets/images/midpoint_logo_hi.png"
            style={{ height: '40px' }}
            className="no-print"
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
});

export default Topbar;
