import React from 'react';
import { Drawer } from '@material-ui/core';

import { useUI } from 'stores/UIStore';
import { useApp } from 'stores/AppStore';

import { makeStyles, useTheme } from '@material-ui/styles';
import { findById } from 'libs/utility';

import RenderRegistry from 'apps/common/RenderRegistry';

import $config from 'config/config';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

function Sidebar(props) {
  const ui = useUI();
  const app = useApp();

  const { open, variant, onClose, className, ...rest } = props;
  const classes = useStyles();

  let sidebar = {};

  let appId = app.state.activeApp;

  if (appId) {
    let activeApp = findById(app.state, appId, { key: 'id' });
    if (activeApp && activeApp.sidebar) {
      let view = findById(app.state, activeApp.sidebar, { key: 'id' }) || {};
      sidebar = {
        ...view,
      };
    }
  }

  sidebar.children = [
    // {
    //   label: 'Home',
    //   path: '/',
    //   type: 'menuItem',
    // },
    ...(sidebar.children || []),
  ];

  if (!app.state._locked) {
    sidebar.children.push({
      label: 'Back to Editor',
      path: '/editor',
      type: 'menuItem',
    });
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
      className={`drawer-${sidebar.name} ${className}`}
    >
      <div
        className={`app-sidebar app-${sidebar.name}`}
        style={{ width: props.sidebarWidth }}
      >
        <span className="app-sidebar-close" onClick={onClose}>
          <svg
            id="i-close"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="32"
            height="32"
            fill="none"
            stroke="currentcolor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <path d="M2 30 L30 2 M30 30 L2 2" />
          </svg>
        </span>
        <RenderRegistry.Render node={sidebar} />
      </div>
      <div className="bottom-sidebar">
        <a href={`${$config.app.server.url}/#/privacy-policy`} target="_blank">
          Privacy Policy
        </a>
      </div>
    </Drawer>
  );
}

export default Sidebar;
