import React from 'react';
import { useApp } from 'stores/AppStore';
import { useDomains } from 'stores/DomainStore';

import StateHelper from 'libs/stateHelper';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import config from 'config/config';
import { useAuth } from 'stores/AuthStore';
import { useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';

const cx = new StateHelper();

const LoginPortal = withRouter((props) => {
  let node = props.node;

  const domains = useDomains();
  const auth = useAuth();
  const app = useApp();
  const { enqueueSnackbar } = useSnackbar();

  if (domains.state.portals.length == 0) {
    props.history.replace('/homeowners-login');
  }

  const _portalToLocalLogin = async (p) => {
    if (app.state.activeAppName !== 'dashboard') {
      if (p.data.user['role_'] !== 'homeowner') {
        enqueueSnackbar('Login failed! Please try again.', {
          variant: 'error',
        });
        return;
      }
    }
    // todo ..change config here!

    console.log(config);
    console.log(p);

    const user = {
      user: p.data.user,
      jwt: p.data.jwt,
      provider: {
        provider: 'local',
      },
      lastLogin: new Date(),
    };

    auth.dispatch(auth.setUser(user));
    props.history.replace('/');
  };

  // todo -- separated from login screen
  let portalsRendered = domains.state.portals.map((p, idx) => {
    const img = p.portal.logo || './assets/images/icon_property.svg';
    const col = p.portal.color || '#000';
    return (
      <div className="portal-menu-item-container" key={`portal-${idx}`}>
        <div className="app-menu-item-login">
          <Button
            onMouseEnter={(e) => {
              e.currentTarget.style.border = `5px solid ${col}`;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.border = 'none';
            }}
            className="btn-app-menu-item-login"
            key={`portal-${idx}`}
            onClick={(e) => {
              e.currentTarget.style.border = `5px solid ${col}`;
              _portalToLocalLogin(p);
            }}
          >
            <div className="inner-button">
              <div class="inner-content-button" style={{ padding: '2em' }}>
                {p.portal.logo ? (
                  <img className="m-2" src={img} />
                ) : (
                  <object type="image/svg+xml" data={img}>
                    <img src={img} />
                  </object>
                )}
              </div>
            </div>
          </Button>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="p-container is-centered pt-2">
        <div className="portal-container">{portalsRendered}</div>
        <div className="portal-logout-button">
          <Button href="/#/homeowners-login" fullWidth>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
});

RenderRegistry.add({
  LoginPortal,
});
