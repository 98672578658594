import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import { useUI } from 'stores/UIStore';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import events from 'libs/events';
import { useModal } from 'components/Modal';
import http from 'libs/http';
import crud from 'libs/crud';
import config from 'config/config';
import { useSnackbar } from 'notistack';

const PaymentModal = (props) => {
  const [state, setState] = React.useState({
    file: { name: 'SELECT .xlsx' },
    isLoading: false,
  });
  const buttonUpload = React.useRef(null);
  const { closeModal } = useModal();
  const { snackbar } = props;

  const onFileChange = (evt) => {
    setState({ ...state, file: evt.target.files[0] });
  };

  const onUpload = async () => {
    setState({ ...state, isLoading: true });
    let fd = new FormData();
    fd.append('file', state.file);
    await http
      .post(config.app.server.url + `/payments/upload`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        events.$emit('fetchData', {});
        snackbar('File uploaded successfully', { variant: 'success' });
      })
      .catch((err) => {
        snackbar('Error uploading file', { variant: 'error' });
      })
      .finally(() => {
        closeModal();
      });
  };

  const onUploadClick = () => {
    buttonUpload.current.click();
  };

  return (
    <div>
      {!state.isLoading ? (
        <div>
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style={{ display: 'none' }}
            type="file"
            ref={buttonUpload}
            onChange={onFileChange}
          />
          <div className="billing-upload-field" onClick={onUploadClick}>
            {state.file.name}
          </div>
          <div class="row mt-3">
            <Button
              className="mx-1"
              onClick={onUpload}
              color="primary"
              variant="contained"
            >
              Upload
            </Button>
            <Button
              className="mx-1"
              onClick={closeModal}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className="billing-upload-loading">
          <CircularProgress color="primary" />
        </div>
      )}
    </div>
  );
};

const PaymentUpload = (props) => {
  const ui = useUI();
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const { enqueueSnackbar } = useSnackbar();

  const { showModal, closeModal } = useModal();

  const onNewPayment = () => {
    showModal({
      title: 'Upload New Payment',
      message: <PaymentModal snackbar={enqueueSnackbar} />,
    });
  };

  const componentEvents = {
    new_payment: onNewPayment,
  };

  React.useEffect(() => {
    events.register(componentEvents);
    return () => {
      events.unregister(componentEvents);
    };
  }, []);

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(node.children, {
        ...props,
        context: context,
      })}
    </React.Fragment>
  );
};

const PaymentHomeownerView = (props) => {
  const { node, context } = props;
  const [state, setState] = React.useState({
    rows: [],
  });

  const statement = context.getState('data.name');

  const headers = ['Posting Date', 'Receipt', 'Item', 'Amount'];

  const getPaymentItems = async () => {
    const p = crud('payments');
    let res = await p.find({ statement: statement });
    let r = [];

    if (res) {
      if (res.data.length > 0) {
        res.data.forEach(async (el, idx) => {
          await http
            .post(config.app.server.url + `/payments/items/${el._id}`, {})
            .then((res) => {
              if (res.data) {
                res.data.forEach((x) => {
                  let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'PHP',
                  });

                  const amnt = formatter.format(x.amount);
                  r.push({
                    postingDate: el.postingDate,
                    receipt: el.receipt,
                    item: x.item,
                    amount: amnt,
                  });
                });
              }
            })
            .finally(() => {
              setState({ ...state, rows: r });
            });
        });
      }
    }

    //setTimeout(()=>{setState({...state, rows: r})}, 500);
  };

  React.useEffect(() => {
    if (statement) {
      getPaymentItems();
    }
  }, [statement]);

  return (
    <div style={{ width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((el, idx) => (
              <TableCell key={idx}>{el}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.rows.map((el, idc) => (
            <TableRow hover>
              {Object.keys(el).map((x, idx) => (
                <TableCell>{el[x]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

RenderRegistry.add({
  PaymentUpload,
  PaymentHomeownerView,
});
