export default function getNotifications(modelName, data) {
  console.log(data);
  switch (modelName) {
    case 'unit-request':
      return `Your ${data.type_.label} for ${data.unit_.number} has been submitted. Our Property Management Group will communicate with you soon for the succeeding steps. Thank you.`;
      break;
    case 'work-permit':
      return `Your Work Permit request for ${data.unit_.number} has been submitted. Our Property Management Group will communicate with you soon for succeeding steps. Thank you.`;
      break;
    case 'pass-delivery':
      return `Your Delivery / Pull-out Pass request for ${data.unit_.number} has been submitted. Please coordinate with our lobby guard/ reception upon your scheduled delivery/pull-out. Thank you.`;
      break;
    case 'pass-visitor':
      return `Your Visitor / Tenant Pass request for ${data.unit_.number} has been submitted. Our Property Management Group will communicate with you after evaluation of your submitted requirements. Thank you.`;
      break;
    case 'online-payment':
      return `Thank you for your payment. Printed receipts will be placed in your mailbox once your payment has cleared thru our account.`;
      break;
    case 'message-board':
      return `Your request for Message Board post has been submitted for review. Our Property Management Group will communicate with you soon for updates. Thank you.`;
      break;
    case 'comments-suggestions':
      return `Thank you for sending your comments and suggestions. Our Property Management Group will communicate with you on your concerns at the soonest time possible. Thank you.`;
      break;
    default:
      return '';
  }
}
