import React, { useState } from 'react';
import { useUI } from 'stores/UIStore';
import Topbar from './main/Topbar';
import Sidebar from './main/Sidebar';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from 'stores/AuthStore';
import { useApp } from 'stores/AppStore';
import { withRouter } from 'react-router-dom';
import { differenceInMinutes, differenceInDays } from 'date-fns';
import crud from 'libs/crud';

import RenderRegistry from 'apps/common/RenderRegistry';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
}));

export default withRouter((props) => {
  const ui = useUI();
  const auth = useAuth();
  const app = useApp();
  let node = props.node;

  const { children, history } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  let counterInterval;

  const handleOnIdle = () => {
    if (auth.state.user.role_ !== 'homeowner') {
      auth.dispatch(auth.setUser({}));
      console.log('Session Expired');
      history.replace(`/${app.state.activeAppName}-login`);
    }
  };

  const handleOnIdleCounters = () => {
    console.log("idling counter...");
    clearInterval(counterInterval);
  };

  const handleOnActiveCounters = () => {
    console.log("activating counter...");
    counterInterval = setInterval(() => {
      getIndicators();
    }, 30000);
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdleCounters,
    onActive: handleOnActiveCounters,
    debounce: 500,
  });

  const checkUser = () => {
    if (auth.state.user.role_ === 'homeowner') {
      if (auth.state.lastLogin) {
        console.log('Checking Session...');
        const result = differenceInDays(
          new Date(),
          new Date(auth.state.lastLogin)
        );
        if (result >= 25) {
          auth.dispatch(auth.setUser({}));
          console.log('Session Expired');
          history.replace(`/${app.state.activeAppName}-login`);
        }
      }
    } else {
      if (auth.state.lastLogin) {
        console.log('Checking Session...');
        const result = differenceInMinutes(
          new Date(),
          new Date(auth.state.lastLogin)
        );
        if (result > 30) {
          auth.dispatch(auth.setUser({}));
          console.log('Session Expired');
          history.replace(`/${app.state.activeAppName}-login`);
        }
      }
    }
  };

  const getIndicators = async () => {
    const c = crud('counters');
    let u = 'all';

    if (app.state.activeAppName === 'homeowners') {
      u = auth.state.user.email;
    }

    let res = await c.find({ user: u });
    if (res) {
      if (res.data) {
        if (res.data.length > 0) {
          ui.dispatch(ui.setState({ indicators: res.data[0].data }));
        }
      }
    }
  };

  React.useEffect(() => {
    checkUser();
    getIndicators();

    counterInterval = setInterval(() => {
      getIndicators();
    }, 30000);

    return () => {
      clearInterval(counterInterval);
    };
  }, []);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar
        className="no-print"
        color="secondary"
        onSidebarOpen={handleSidebarOpen}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
        className="no-print"
      />
      <main className={classes.content}>
        {node ? <RenderRegistry.Render node={node} /> : props.children}
      </main>
    </div>
  );

  // return (
  //   <div
  //     style={{ display: 'flex', paddingLeft: sidebarWidth, paddingTop: '80px' }}
  //   >
  //     <Topbar />
  //     <Sidebar sidebarWidth={sidebarWidth} />
  //     <main style={{ flex: 1 }}>
  //       {node ? <RenderRegistry.Render node={node} /> : props.children}
  //     </main>
  //   </div>
  // );
});
