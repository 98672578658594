import React from 'react';
import { useApp } from 'stores/AppStore';
import cache from 'libs/cache';
import events from 'libs/events';
import RenderRegistry from '../RenderRegistry';
import { withRouter } from 'react-router-dom';
import { findById } from 'libs/utility';
import DataModel from '../data/DataModel';
import debounce from 'debounce';
import pluralize from 'pluralize';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import crud from 'libs/crud';
import getNotifications from '../../pacificland/modelNotifications';
import { useAuth } from 'stores/AuthStore';
import qs from 'qs';

const cachedContext = {};
const nullDataModel = new DataModel({});

const Page = withRouter((props) => {
  const app = useApp();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  let node = findById(app.state, props.node.id, { key: 'id' }) || {};
  let name = node.name || node.id;
  const noPadding = node.noPadding || false;

  let initData = { data: { meta: {} } };
  let role = auth.state.user['role_'] || 'admin';

  if (node.access) {
    if (Object.keys(auth.state.user).length > 0) {
      let roles = node.access.split(',');

      if (!roles.includes(role)) {
        initData['no-access'] = true;
      }
    }
  }

  const [state, setState] = React.useState(initData);

  const renderChildren = RenderRegistry.renderChildren;

  let context;
  let routePath;

  let model = node.dataModel;
  let modelName = 'data';
  let modelDef;
  if (model) {
    modelDef = (app.state.children || []).filter((c) => {
      return c.type === 'model' && c.id === model;
    })[0];
  }

  if (modelDef) {
    context = cachedContext[node.id] || new DataModel(modelDef);
    cachedContext[node.id] = context;

    context.useState(state, setState);
    routePath = pluralize(modelDef.name);
  }

  // todo validation

  let params_ = {};

  let q = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  let page = 0;

  if (q.page) {
    page = q.page;
  }

  if (node.params) {
    try {
      params_ = JSON.parse(node.params) || {};
      console.log(params_);
    } catch (err) {}
  }

  React.useEffect(() => {
    if (model && !state.data._id) {
      fetchData();
    }
  }, [model, page]);

  const fetchCount = async (params) => {
    if (props.match) {
      params = {
        ...params_,
        ...props.match.params,
        ...params,
      };

      if (params.id === '0') {
        delete params.id;
        context.setState({
          data: { meta: {} },
        });
        return;
      }
    }

    if (params && params.filter && modelDef) {
      let filter = { ...params.filter };
      delete params.filter;

      if (filter.search) {
        modelDef.children.forEach((c) => {
          if (c.type.indexOf('field') === 0) {
            if (c.name !== 'status') {
              if (c.dataType == 'relation') {
                params[`or:${c.name}_.name_regex`] = filter.search;
              } else {
                params[`or:${c.name}_regex`] = filter.search;
              }
            }
          }
        });
        delete filter.search;
      }

      params = { ...params, ...filter };
    }

    const c = crud(`${pluralize(modelDef.name)}/count`);

    c.request({ ...params }, { method: 'GET' })
      .then((res) => {
        let d = { [`count`]: res.data };
        // if (role == 'homeowner') {
        //   d['no-access'] = true;
        // }
        context.setState(d);
      })
      .catch((err) => {
        enqueueSnackbar('error loading', { variant: 'error' });
      });
  };

  const fetchData = async (params) => {
    await fetchCount(params);

    if (props.match) {
      params = {
        ...params_,
        ...props.match.params,
        ...params,
      };

      if (params.id === '0') {
        delete params.id;
        context.setState({
          data: { meta: {} },
        });
        return;
      }
    }

    if (params && params.filter && modelDef) {
      let filter = { ...params.filter };
      delete params.filter;

      if (filter.search) {
        modelDef.children.forEach((c) => {
          if (c.type.indexOf('field') === 0) {
            if (c.name !== 'status') {
              if (c.dataType == 'relation') {
                params[`or:${c.name}_.name_regex`] = filter.search;
              } else {
                params[`or:${c.name}_regex`] = filter.search;
              }
            }
          }
        });
        delete filter.search;
      }
    }

    let skip = parseInt(page) * 50;

    context
      .find({ ...params, _sort: 'createdAt:DESC', _skip: skip })
      .then((res) => {
        let d = { [`data`]: res.data };
        if (role == 'homeowner') {
          d['no-access'] = true;
        }
        context.setState(d);
      })
      .catch((err) => {
        enqueueSnackbar('error loading', { variant: 'error' });
      });
  };

  const onFilter = debounce(async (params) => {
    fetchData({
      filter: params,
    });
  }, 50);

  const onNew = async (params) => {
    props.history.push(`/${routePath}/0`);
    context.setState({
      data: {},
    });
  };

  const onOpen = async (params) => {
    props.history.push(`/${routePath}/${params._id}`);
    context.setState({
      data: {},
    });
  };

  const onSave = async (params) => {
    await context.validateStateWithWait();

    if (context._state._errors['terms-conditions'] == undefined) {
      if (context.hasErrors()) {
        enqueueSnackbar('Please fill up all required fields.', {
          variant: 'error',
        });
        return;
      }
    }

    const tc = context.getState('terms-conditions');

    if (tc !== undefined) {
      if (!tc) {
        enqueueSnackbar('Accept Terms and Conditions to continue.', {
          variant: 'error',
        });
        const err = {
          'terms-conditions': {
            invalid: 'true',
            error: 'isRequired',
            message: 'This field cannot be empty',
          },
        };
        context.putError(err);
        return;
      } else {
        context.removeError('terms-conditions');
      }
    }

    try {
      let res = await context.save();
      context.setState({
        data: res.data,
      });

      // let p = props.match.path.replace(':id', res.data.id);
      // props.history.replace(p);
      console.log(app.state.activeAppName);
      if (app.state.activeAppName == 'dashboard') {
        props.history.push(`/${pluralize(modelDef.name)}-list`);
      } else {
        let homeowner = cache.get('homeowner', {});
        console.log(res.data);
        let notif = getNotifications(modelDef.name, res.data);
        if (notif.length > 0) {
          const n = crud('notifications');
          let d = new Date();
          d.setDate(d.getDate() + 7);
          await n.save({
            name: homeowner.name,
            text: notif,
            homeowner: homeowner._id,
            homeowner_: homeowner,
            noticeStart: new Date(),
            noticeEnd: d,
          });
        }
        props.history.push('/');
      }
      enqueueSnackbar('Submitted', { variant: 'success' });
    } catch (err) {
      // console.log(err);
      enqueueSnackbar('error saving', { variant: 'error' });
    }
  };

  const onDelete = async (params) => {
    await context.erase();
    props.history.replace(`/${routePath}-list`);
  };

  const onBack = (params) => {
    props.history.goBack();
  };

  const onLogout = () => {
    props.history.goBack();
  };

  const onPrint = () => {
    window.print();
  };

  const pageEvents = {
    open: onOpen,
    new: onNew,
    save: onSave,
    delete: onDelete,
    filter: onFilter,
    back: onBack,
    logout: onLogout,
    fetchData: fetchData,
    print: onPrint,
  };

  React.useEffect(() => {
    events.register(pageEvents);
    return () => {
      events.unregister(pageEvents);
    };
  });

  let customClass = !noPadding
    ? 'app-page-padding bs-' + (name || '').replace(/\s/g, '-')
    : 'bs-' + (name || '').replace(/\s/g, '-');
  let className = node.className || '';

  return (
    <div className={clsx('app-page', customClass, className)}>
      {RenderRegistry.renderChildren(node.children, {
        // ...props,
        context,
      })}
      {/*<pre>{JSON.stringify(context, null, 4)}</pre>*/}
    </div>
  );
});

RenderRegistry.add({
  page: Page,
});
