import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import clsx from 'clsx';

const UnitRequest = (props) => {
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const name = node.name || '';

  const getView = (n) => {
    const viewIndex = children.findIndex((x) => x.name === n);
    return (
      <React.Fragment>
        {viewIndex >= 0 ? (
          RenderRegistry.renderChildren([children[viewIndex]], {
            ...props,
            context: context,
          })
        ) : (
          <pre>not found</pre>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {getView('unit-container')}
      {getView('type-container')}
      <div key={context._state.data.type}>
        {context._state.data.type &&
          getView(`${name}-${context._state.data.type}`)}
      </div>
    </React.Fragment>
  );
};

const UnitRequestPlansDocuments = (props) => {
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const plans = context.getState('data.meta.plans') || 'standard';

  return (
    <div>
      {plans == 'as-built' &&
        RenderRegistry.renderChildren(node.children, {
          ...props,
          context: context,
        })}
    </div>
  );
};

RenderRegistry.add({
  UnitRequest,
  UnitRequestPlansDocuments,
});
