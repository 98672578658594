import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import crud from 'libs/crud';
import { useSnackbar } from 'notistack';
import events from 'libs/events';
import cache from 'libs/cache';
import getNotifications from './modelNotifications';

const Paymaya = (props) => {
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const name = node.name || '';
  const { enqueueSnackbar } = useSnackbar();
  const payment = crud('online-payments');

  const onPay = async () => {
    await context.validateStateWithWait();

    if (context._state._errors['terms-conditions'] == undefined) {
      if (context.hasErrors()) {
        enqueueSnackbar('Please fill up all required fields.', {
          variant: 'error',
        });
        return;
      }
    }

    try {
      let res = await payment.request(context._state.data, { method: 'POST' });
      window.location.href = res.data.redirectUrl;
      enqueueSnackbar('Submitted', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }
  };

  const componentEvents = {
    pay: onPay,
  };

  React.useEffect(() => {
    events.register(componentEvents);
    return () => {
      events.unregister(componentEvents);
    };
  });

  return (
    <React.Fragment>
      {RenderRegistry.renderChildren(children, {
        ...props,
        context: context,
      })}
      {/*<pre>{JSON.stringify(context, null, 4)}</pre>*/}
    </React.Fragment>
  );
};

RenderRegistry.add({
  Paymaya,
});
