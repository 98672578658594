import React from 'react';
import RenderRegistry from '../common/RenderRegistry';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';
import crud from 'libs/crud';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import config from 'config/config';
import Avatar from '@material-ui/core/Avatar';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import {
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
} from '@material-ui/core';
import Icon from 'components/icons/Icon';

const MessageBoard = withRouter((props) => {
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;

  const [posts, setPosts] = React.useState([]);

  const p = crud('message-boards');

  const getPosts = async () => {
    const res = await p.find({ status: 'approved', _sort: 'datePosted:DESC' });
    if (res) {
      if (res.data.length > 0) {
        setPosts(res.data);
      }
    }
  };

  React.useEffect(() => {
    getPosts();
  }, []);

  return (
    <React.Fragment>
      <GridList cols={4} cellHeight={180} style={{ padding: '1em 0' }}>
        {Array.isArray(posts) &&
          posts.map((post) => {
            let url = `${config.app.server.url}/storage/${post.photos[0]}`;
            if (typeof post.photos[0] == 'object') {
              url = `${config.app.server.url}${post.photos[0].url}`;
            }
            return (
              <GridListTile
                component="a"
                onClick={() =>
                  props.history.push('/message-board-post/' + post._id)
                }
                key={post._id}
                style={{ padding: '.2em', width: '50%', height: '400px' }}
              >
                <img
                  src={
                    post.photos.length > 0
                      ? `${url}`
                      : `./assets/images/icon_missing_item.svg`
                  }
                  alt={post.title}
                />
                <GridListTileBar
                  title={post.title}
                  subtitle={
                    <span>{post.topic_ ? post.topic_.label : post.topic}</span>
                  }
                />
              </GridListTile>
            );
          })}
      </GridList>
      {/*<pre>{JSON.stringify(context._state, null, 4)}</pre>*/}
    </React.Fragment>
  );
});

const MessageBoardInfo = withRouter((props) => {
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const post = context._state.data || { homeowner: {} };

  return (
    <div className={clsx('p-2', cls)}>
      <Typography className="m-1" color="primary" variant="subtitle2">
        Click photo to see entire image.
      </Typography>
      {post && (
        <Typography className="m-1" variant="h3">
          {post.title}
        </Typography>
      )}
      {post && post.price && (
        <Typography style={{ fontWeight: '600' }} className="m-1" variant="h5">
          &#8369; {post.price}
        </Typography>
      )}
      {post && post.topic_ && (
        <Typography style={{ fontWeight: '600' }} className="m-1" variant="h5">
          {post.topic_ ? post.topic_.label : ''}
        </Typography>
      )}
      {post && (
        <Typography variant="body1" className="mt-2 mb-1">
          {post.description}
        </Typography>
      )}

      {post.homeowner && (
        <div className="message-board-profile">
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <img
                    src={
                      post.homeowner.photo.length > 0
                        ? `${config.app.server.url}/storage/${post.homeowner.photo[0]._id}`
                        : `/assets/images/placeholder-profile-pic.png`
                    }
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${post.homeowner.name}`}
                secondary={post.contactNumber || post.homeowner.contact || ''}
              />
            </ListItem>
          </List>
        </div>
      )}
    </div>
  );
});

const MessageBoardComments = withRouter((props) => {
  const { node, context } = props;
  const cls = props.node.className;
  const children = node.children;
  const post = context._state.data || { title: '' };

  return (
    <div className={clsx('p-2', cls)}>
      {/*post && (
        <Typography className="m-1" variant="h6">
          Comments
        </Typography>
      )*/}

      {/*<pre>{JSON.stringify(context._state, null, 4)}</pre>*/}
    </div>
  );
});

RenderRegistry.add({
  MessageBoard,
  MessageBoardInfo,
  MessageBoardComments,
});
